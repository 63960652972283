export default function stringHelper() {

    const divNameOnly = (d) => {
        return d ? d.replace(/\d+\s-\s/, '') : d;
    };

    const pluralize = (word, num, includeNum=true, pluralWord=null) => {
        if (!['d'].includes(word[word.length - 1])) {
            pluralWord = !pluralWord ? `${word}s` : pluralWord;
            word = num !== 1 ? `${pluralWord}`: `${word}`;
        }

        return includeNum ? `${num} ${word}` : `${word}`;
    };

    const capitalize = (word) => {
        return word ? word[0].toUpperCase() + word.slice(1) : word;
    }

    return {
        divNameOnly,
        pluralize,
        capitalize
    }
}